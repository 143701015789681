import '../css/MomentsPage.css'
import '../css/Policy.css'
import 'react-toastify/dist/ReactToastify.css';
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ConsentBanner } from '../components/ConsentBanner'

export const CookiePolicy = () => {

    return (
        <div className="cookie-policy-page">
            <Header />
            <div id='main' className='content-feed-wrapper'>
                <div className="policy-content">
                    <h2>Cookie Policy for Soonfeed Media</h2>
                    <p>This is the Cookie Policy for Soonfeed Media, a website published by The Right Street sprl and accessible from <a href="https://soonfeed.com/" target="_blank" rel="noreferrer noopener">https://soonfeed.com/.</a></p>
                    <div>
                        <h3>What Are Cookies?</h3>
                        <p>This site uses cookies, which are tiny files that are downloaded to your computer to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored, however this may result in the reduced functionality of certain parts of the website.</p>
                        <p>For more general information on cookies, please read&nbsp;“<a href="https://www.cookieconsent.com/what-are-cookies/" target="_blank" rel="noreferrer noopener">What Are Cookies?</a>”. Information regarding cookies from this Cookies Policy are from&nbsp;<a href="https://www.generateprivacypolicy.com/" target="_blank" rel="noreferrer noopener">the Privacy Policy Generator</a> and other relevant sources.</p>
                    </div>
                    <div>
                        <h3>How We Use Cookies</h3>
                        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site.</p>
                    </div>
                    <div>
                        <h3>Disabling Cookies</h3>
                        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the website. This Cookies Policy was created with the help of the Cookies Policy Generator from&nbsp;<a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/" target="_blank" rel="noreferrer noopener">CookiePolicyGenerator.com</a> and other relevant sources.</p>
                    </div>
                    <div>
                        <h3>The Cookies We Set</h3>
                        <ul><li>Account related cookies</li></ul>
                        <p>If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.</p>
                        <ul><li>Email newsletters related cookies</li></ul>
                        <p>This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed users.</p>
                        <p>We might use your information and place you on our newsletter lists to send you marketing emails about our services or moments that are similar or related to those you have previously joined, if you opted in for this.</p>
                        <ul><li>Forms related cookies</li></ul>
                        <p>When you submit data through a form such as those found on contact pages or comment forms, cookies may be set to remember your user details for future correspondence.</p>
                        <ul><li>Site preferences cookies</li></ul>
                        <p>In order to provide you with the best experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.</p>
                        <p>Our editors may use cookies to serve you with advertisements that we believe are relevant to you and your interests if you opted in for this. For example, if you read a number of articles on upcoming moments on our website or on other sites, the editors might assume you are interested in this topic and serve you with its advertisements. You might see these advertisements on our website and on other sites that you visit.</p>
                    </div>
                    <div>
                        <h3>Third Party Cookies</h3>
                        <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
                        <ul><li>Tracking- and Marketing-Cookies</li></ul>
                        <p>These cookies help us analyse user behaviour and based on this information, optimise the usability. Furthermore, these cookies enable us to display advertisements adapted to your interests.</p>
                        <p>We only activate the tracking and marketing cookies mentioned below after you have given us your consent via the cookie banner.</p>
                        <p>Cookies are also operated by the US companies Google LLC and FB Inc., which process data in the USA. By agreeing to the use of cookies from these providers, you also expressly consent to the processing of your data by these companies in the USA (Art 49 Paragraph 1 lit a GDPR). The European Court of Justice (ECJ) has not certified the USA as having an adequate level of data protection (judgment C-311/18). In particular, the ECJ criticized the fact that the access options for US authorities provided for in US law are not limited to what is absolutely necessary, that there are no judicial authorisations for such access and that EU citizens have no effective legal remedies. There is therefore a risk that your data will also be subject to access by US authorities for control and monitoring purposes.</p>
                        <p>Our cookie-banner allows you to activate and deactivate our tracking- and marketing-cookies at any time.</p>
                        <ul>
                            <li><p>This website uses Google Analytics, a web analysis service of Google Inc. ("Google"). Google Analytics uses so-called "cookies", text files that are saved on your computer and that permit an analysis of your usage of the website. The information generated by the cookie regarding your usage of this website (including your IP address) will be transmitted to a server of Google in the US and saved there. Google will use this information in order to analyse your usage of the website, to compile reports on the website activities for the website operators and to provide further services associated with the usage of the website and of the Internet. Google may also transmit this information to third parties if this is stipulated by law or if third parties process these data on behalf of Google. Under no circumstances will Google link your IP address to other data of Google. You can prevent the installation of the cookies by a corresponding setting in your browser software; however, we would like to point out that in this case you may not be able to use all functions of this website in full. By using this website, you declare your consent to the processing of the data collected about you by Google in the form and manner described above and for the aforementioned purpose. If you do not wish to send any information to Google Analytics, you can do this by installing the Google Analytics Opt-out browser add-on.</p></li>
                            <li><p>Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.</p></li>
                            <li><p>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</p></li>
                            <li><p>It's important for us to understand statistics about how many of the visitors to our site actually make a conversion and as such this is the kind of data that these cookies will track. This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising.</p></li>
                            <li><p>We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites including; Facebook (Facebook Pixel), will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</p></li>
                            <li><p>We use a "Facebook Pixel" of the social network Facebook Inc. ("Facebook"). The Facebook pixel can track the behavior of users after clicking on a Facebook ad. With the aid of the Facebook pixel, we can understand how our marketing measures are being adopted on Facebook and, if necessary, are taking optimization measures. If you want to disable cookie storage for Facebook, you need to change your browser settings. Please use the help menu of your browser for further information. The data collected remains anonymous and does not provide any information about the identity of the users. Facebook stores and processes the data for its own advertising purposes in accordance with the Facebook Data Directive (<a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer noopener">https://www.facebook.com/about/privacy/</a>).</p></li>
                            <li><p>This website uses retargeting technology for online advertising. This makes it possible to specifically address the Internet browsers with advertising that have already shown interest in our content. It is known from studies that the display of interest-based advertising is more interesting for the Internet user than advertising that has no such relevance. With retargeting, the display of the advertising media is done on the basis of a cookie-based analysis of the previous visit behaviour. This website uses the Google Analytics functions for marketing tools such as remarketing lists for search ads and remarketing for the Google display network. It goes without saying that no personal data is saved during this process. If you do not wish interest-based advertising from Google to be displayed, you can deactivate this function here: <a href="https://www.google.com/settings/ads/onweb/?hl=en" target="_blank" rel="noreferrer noopener">https://www.google.com/settings/ads/onweb/?hl=en</a></p></li>
                            <li><p>LinkedIn Insight Tag is the code we place on our site. It is used by the social networking service, LinkedIn, for tracking the use of embedded services. You can learn more on the technology at this link: <a href="https://business.linkedin.com/marketing-solutions/ad-targeting/website-retargeting" target="_blank" rel="noreferrer noopener">https://business.linkedin.com/marketing-solutions/ad-targeting/website-retargeting</a> In this case, LinkedIn is the responsible party for showing the ads to you. You may revoke your consent at <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out?trk=" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out?trk=</a></p></li>
                        </ul>
                    </div>
                    <div>
                        <h3>More Information</h3>
                        <p>We hope this has clarified our Cookie policy for you. If you need more information you can contact us at&nbsp;<a href="mailto:hello@soonfeed.com" target="_blank" rel="noreferrer noopener">hello@soonfeed.com</a></p>
                    </div>
                </div>
                <Footer />
                <ConsentBanner />
            </div>
        </div>
    )
}