import { useState, useEffect } from 'react'
import axios from '../../axios';
import { Admin } from '../../components/admin_components/Admin';

export const AdminAdmins = (params) => {

    const [loading, setLoading] = useState(false)

    const [admins, setAdmins] = useState([])

    useEffect(() => {
        axios.get(`admin/admins`).then(response => {
            console.log(response)
            setAdmins([...response.data.result])
        })
        return () => {

        }
    }, [])

    return <div className="admin-admins">
        <div className='content-left'>
            {
                admins == null ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    :
                    admins.map((admin, admin_index) =>
                        <Admin admin={admin} key={admin_index} />
                    )
            }
        </div>
    </div>
}