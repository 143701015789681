import { useState } from 'react'
import CLOSE_ICON from '../img/close-icon.svg'
import '../css/Modal.css'
import axios from '../axios'

export const JoinModal = (params) => {
    const { modalData, closeModal } = params
    const accentedLetters = "ČčĆćĐđŠšŽžàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ"
    const patternName = new RegExp(`^[a-z${accentedLetters}]{2,}([-']?[a-z${accentedLetters}]+)*( [a-z${accentedLetters}]([-']?[a-z${accentedLetters}]+)*)*$`, 'i')
    const patternEmail = new RegExp(/^[\w.]+@[\w.]+$/i)
    const [name, setName] = useState(localStorage.getItem("soonfeed_name") ?? '');
    const [email, setEmail] = useState(localStorage.getItem("soonfeed_email") ?? '');
    const [errorName, setErrorName] = useState((localStorage.getItem("soonfeed_name") && !patternName.test(name)) || false);
    const [errorEmail, setErrorEmail] = useState((localStorage.getItem("soonfeed_email") && !patternEmail.test(email)) || false);
    const [consentCheck, setConsentCheck] = useState(true)
    const [loading, setLoading] = useState(false)

    const joinMoment = () => {
        setLoading(true)
        axios.post(`moments/join`, {
            moment_id: modalData.moment.moment_id,
            email,
            name
        }).then((response) => {
            console.log(response)
            if (response.status === 200) {
                if (email !== localStorage.getItem("soonfeed_email")) {
                    localStorage.setItem("soonfeed_email", email)
                    localStorage.removeItem("soonfeed_joined_moments")
                    closeModal(true, true)
                }
                else {
                    closeModal(true)
                }
                setLoading(false)
            }
        })
    }

    return (
        <div className="modal-container">
            <div className='modal-cover' onClick={() => { closeModal(false) }}></div>
            <div className='modal'>
                <div className='modal-header'>
                    <h3 className='modal-title'>{modalData.moment.feed_title}</h3>
                    <img className='modal-close' src={CLOSE_ICON} alt="Close" onClick={() => { closeModal(false) }} />
                </div>
                <div className='modal-content'>
                    <h4 className='modal-subtitle'>{modalData.moment.title}</h4>
                    <div className={'input-row' + (name.length === 0 ? "" : errorName ? " validate-red" : " validate-green")}>
                        <input type="text" placeholder="Full Name" value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrorName(!patternName.test(e.target.value));
                                localStorage.setItem("soonfeed_name", e.target.value)
                            }} />
                        {name.length === 0 ? null : errorName ? <i className="fas fa-times validate-red"></i>
                            : <i className="fas fa-check validate-green"></i>}
                    </div>
                    <div className={'input-row' + (email.length === 0 ? "" : errorEmail ? " validate-red" : " validate-green")}>
                        <input type="text" placeholder="Email" value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErrorEmail(!patternEmail.test(e.target.value));
                            }} />
                        {email.length === 0 ? null : errorEmail ? <i className="fas fa-times validate-red"></i>
                            : <i className="fas fa-check validate-green"></i>}
                    </div>
                    <div className='checkbox-button-wrapper'>
                        <div className='checkbox-row'>
                            <input type="checkbox" className='consent-checkbox' checked={consentCheck} onClick={() => { setConsentCheck(!consentCheck) }} />
                            <p>Yes, I would like to receive newsletter notifications from the Editors of Soonfeed Moments listed under the Privacy Policy.</p>
                        </div>
                        <div className='button-row'>
                            {loading
                                ? <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="40" cy="40" r="30"></circle>
                                </svg>
                                : <div>
                                    <button className='cancel-btn' onClick={() => { closeModal(false) }}>CANCEL</button>
                                    <button className={'join-btn' + ((errorName || errorEmail || name.length === 0 || email.length === 0) ? ' disabled' : '')} onClick={(errorName || errorEmail || name.length === 0 || email.length === 0) ? null : joinMoment}>JOIN</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}