import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from '../axios';

export const AdminLogin = (params) => {

    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState('')

    const [full_name_register, setFullNameRegister] = useState('');
    const [email_register, setEmailRegister] = useState('');
    const [password_register, setPasswordRegister] = useState('');
    const [key_register, setKeyRegister] = useState('');
    const [errorRegister, setErrorRegister] = useState('')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`admin`).then(response => {
            console.log(response)
            navigate("/admin");
        }).catch((error) => {
            if (error?.response?.status === 401) {
            }
            else {
                console.error(error)
            }
        }).finally(() => {
            setLoading(false)
        })
        return () => {

        }
    }, [])

    const login = (e) => {
        e.preventDefault();
        setLoading(true)
        axios.post(`admin/login`, {
            email,
            password,
        }).then(response => {
            console.log(response)
            navigate("/admin");
        }).catch((error) => {
            if (error?.response?.status === 403) {
                navigate("/admin");
            }
            else if (error?.response?.status === 401) {
                setPassword('')
                setErrorLogin(error?.response?.data?.message)
            }
            else {
                console.error(error)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const register = (e) => {
        e.preventDefault();
        setLoading(true)
        axios.post(`admin/register`, {
            full_name: full_name_register,
            email: email_register,
            password: password_register,
            key: key_register
        }).then(response => {
            console.log(response)
            window.location.reload()
        }).catch((error) => {
            if (error?.response?.status === 403) {
                navigate("/admin");
            }
            else if (error?.response?.status === 401) {
                setErrorRegister(error?.response?.data?.message)
            }
            else {
                console.error(error)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return <div className="admin-panel">
        admin login
        <form onSubmit={login}>
            <input type="text" placeholder='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
            <input type="password" placeholder='password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
            {
                loading ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    : <button type='submit'>Login</button>
            }
            <p>{errorLogin}</p>
        </form>
        <form onSubmit={register}>
            <input type="text" placeholder='full name' value={full_name_register} onChange={(e) => { setFullNameRegister(e.target.value) }} />
            <input type="text" placeholder='email' value={email_register} onChange={(e) => { setEmailRegister(e.target.value) }} />
            <input type="password" placeholder='password' value={password_register} onChange={(e) => { setPasswordRegister(e.target.value) }} />
            <input type="password" placeholder='key' value={key_register} onChange={(e) => { setKeyRegister(e.target.value) }} />
            {
                loading ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    : <button type='submit'>Register</button>
            }
            <p>{errorRegister}</p>
        </form>
    </div>
}