import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from '../axios';
import SOONFEED_LOGO_SVG from '../img/sf-logo.svg'
import SOONFEED_MOBILE_LOGO_SVG from '../img/soonfeed-mobile-logo.svg'

export const Header = () => {

    const [feeds, setFeeds] = useState([])
    const [showTwitterDropdown, setShowTwitterDropdown] = useState(false)

    useEffect(() => {
        axios.get(`feeds`).then(response => {
            console.log(response)
            setFeeds([...response.data.result])
        })
        return () => {

        }
    }, [])


    return (
        <header>
            <div className='logo-beforetime-wrapper desktop'>
                <div id="soonfeed-link">
                    <a href='/'>
                        <img src={SOONFEED_LOGO_SVG} alt="Soonfeed - Moments Beforetime" title='Soonfeed logo' height="50" />
                    </a>
                </div>
                <div className='moments-beforetime'>
                    <p>moments<br />beforetime</p>
                </div>
            </div>
            <div className='logo-beforetime-wrapper mobile'>
                <div id="soonfeed-link">
                    <a href='/'>
                        <img src={SOONFEED_MOBILE_LOGO_SVG} alt="Soonfeed - Moments Beforetime" title='Soonfeed logo' height="50" />
                    </a>
                </div>
            </div>
            <div className='feed-socials-wrapper'>
                {feeds == null || feeds?.length === 0 ?
                    null
                    : <NavLink to={`/${feeds[0]?.feed_route}`} className="feed-button-link">
                        <div className='feed-button'><h2>{feeds[0]?.title}</h2></div>
                    </NavLink>
                }
                <div className="socials">
                    <div className="socials-item" onClick={() => { setShowTwitterDropdown(!showTwitterDropdown) }}>
                        <i className="fab fa-twitter"></i>
                        {showTwitterDropdown ?
                            <div className='twitter-dropdown-overlay' onClick={() => { setShowTwitterDropdown(false) }}>
                            </div>
                            : null
                        }
                        {showTwitterDropdown ?
                            <div className='twitter-dropdown'>
                                <a href='https://twitter.com/Soonfeed' target="_blank" rel='noreferrer'>@Soonfeed</a>
                                <a href='https://twitter.com/SoonfeedEU' target="_blank" rel='noreferrer'>@SoonfeedEU</a>
                                <a href='https://twitter.com/SoonfeedLIVE' target="_blank" rel='noreferrer'>@SoonfeedLIVE</a>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}