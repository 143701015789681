import { useState, useEffect } from 'react'
import axios from '../../axios';
import { User } from '../../components/admin_components/User';
import '../../css/AdminPanel.css'

export const AdminUsers = (params) => {

    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])

    useEffect(() => {
        axios.get(`users`).then(response => {
            console.log(response)
            setUsers([...response.data.result])
        })
        return () => {

        }
    }, [])

    return <div className="admin-users">
        <div className='content-left'>
            {
                users == null ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    :
                    users.map((user, user_index) =>
                        <User user={user} key={user_index} />
                    )
            }
        </div>
    </div>
}