export const AboutFeedProvider = (params) => {
    const { provider } = params

    return (
        <div className="moment-author">
            <div className="moment-author-avatar">
                <img src="/logo.jpg" alt="Soonfeed logo"></img>
            </div>
            <div className="moment-author-name">
                {provider?.name}
            </div>
        </div>
    )
}