export const Footer = () => {
    return (
        <footer>
            <div className='flex-space-between'>
                <h6>
                    Founder & Chief Editor: <b>Richard Medic</b>
                </h6>
                <h6 className="desktop">
                    <b><a href='/privacy-policy' target="_blank" rel='noopener'>Soonfeed Media’s Privacy Policy</a> </b>
                </h6>
            </div>
            <div className='flex-space-between'>
                <h6>
                    Publisher: <b>The Right Street sprl</b> (Brussels)
                </h6>
                <h6 className="desktop">
                    <b><a href='/cookie-policy' target="_blank" rel='noopener'>Soonfeed Media’s Cookie Policy</a> </b>
                </h6>
            </div>
            <h6 className='justify-self-start'>
                <b>hello@soonfeed.com</b>
            </h6>
            <div className='flex-column mobile'>
                <h6>
                    <b><a href='/privacy-policy' target="_blank" rel='noopener'>Soonfeed Media’s Privacy Policy</a> </b>
                </h6>
                <h6>
                    <b><a href='/cookie-policy' target="_blank" rel='noopener'>Soonfeed Media’s Cookie Policy</a> </b>
                </h6>
            </div>
            <h6>
                <i>Copyright 2012-2022</i>
            </h6>
        </footer>
    )
}