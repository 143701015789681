const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
]
const monthsLong = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]
const days = [
    "SUN",
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT"
]
const daysLong = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

const initMoment = (moment) => {
    const localTimezoneOffset = (new Date()).getTimezoneOffset()
    //Hard-code CET (+01:00) timezone
    //const localTimezoneOffset = -60

    let date_start
    let diff_start
    let year_start
    let month_start
    let day_start
    let hour_start
    let minute_start
    let second_start
    if (moment?.moment_start != null) {

        year_start = parseInt(moment?.moment_start.substring(0, 4))
        month_start = parseInt(moment?.moment_start.substring(5, 7))
        day_start = parseInt(moment?.moment_start.substring(8, 10))
        hour_start = parseInt(moment?.moment_start.substring(11, 13))
        minute_start = parseInt(moment?.moment_start.substring(14, 16))
        second_start = parseInt(moment?.moment_start.substring(17, 19))
        date_start = new Date(year_start, month_start - 1, day_start, hour_start, minute_start - localTimezoneOffset, second_start)

        //This might not be supported by all browsers
        //date_start = new Date(moment?.moment_start)
        diff_start = date_start - Date.now()
    }

    return { date_start, diff_start }
}

const isOver = (moment, diff_start) => (moment?.start_time_specified === 1 && diff_start != null && Math.floor((diff_start) / (1000 * 60)) < -60)
    || (moment?.start_time_specified === 0 && ((diff_start != null && Math.floor((diff_start) / (1000 * 60 * 60 * 24)) < -1) || moment?.moment_state === -1))

const parseTopTime = (moment, date_start, diff_start) => {

    if (moment?.moment_start != null) {
        //over a year (MM)
        return Math.floor((diff_start) / (1000 * 60 * 60 * 24 * 365)) >= 1 ? `${months[date_start.getMonth()]}`//Check if specific time is known
            //over 1 week (DD)
            : Math.floor((diff_start) / (1000 * 60 * 60 * 24 * 7)) >= 1 ? `${date_start.getDate()}`
                : moment?.start_time_specified === 1 ?
                    (
                        //over 2 days (HH.MM)
                        Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 2 ? `${date_start.getHours().toString().padStart(2, '0')}.${date_start.getMinutes().toString().padStart(2, '0')}`
                            //over 1 day (HH.MM)
                            : Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 1 ? `${date_start.getHours().toString().padStart(2, '0')}.${date_start.getMinutes().toString().padStart(2, '0')}`
                                //over 1 hour (HH.MM)
                                : Math.floor((diff_start) / (1000 * 60 * 60)) >= 1 ? `${date_start.getHours().toString().padStart(2, '0')}.${date_start.getMinutes().toString().padStart(2, '0')}`
                                    //over 1 minute (MM)
                                    : Math.floor((diff_start) / (1000 * 60)) >= 1 ? `${Math.floor((diff_start) / (1000 * 60))}`
                                        //less than 1 minute (SS s)
                                        : diff_start > 0 ? `${Math.floor(((diff_start) / 1000))}`
                                            //started (and hasn't been going for longer than 60 mins)
                                            : Math.floor((diff_start) / (1000 * 60)) >= -60 ? `NOW`
                                                //ended
                                                : `OVER`
                    ) : (
                        //over 2 days (Day)
                        Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 2 ? `${days[date_start.getDay()]}`
                            //over 1 day (TOMORROW)
                            : Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 1 ? `TOMORROW`
                                //date passed (over)
                                : isOver(moment, diff_start) ? `OVER`
                                    //hasn't started yet (manually set by state)
                                    : (moment?.moment_state === 0) ? `TODAY`
                                        //started (manually set by state)
                                        : (moment?.moment_state === 1) ? `NOW`
                                            //ended (manually set by state)
                                            : `OVER`
                    )
    }
    else {
        return moment?.moment_state === 0 ? `SOON`
            : moment?.moment_state === 1 ? `NOW`
                : `OVER`
    }
}
const parseBottomTime = (moment, date_start, diff_start) => {

    if (moment?.moment_start != null) {
        //over a year (YYYY)
        return Math.floor((diff_start) / (1000 * 60 * 60 * 24 * 365)) >= 1 ? `${date_start.getFullYear()}`
            //over 1 week (Month)
            : Math.floor((diff_start) / (1000 * 60 * 60 * 24 * 7)) >= 1 ? `${months[date_start.getMonth()]}`
                : moment?.start_time_specified === 1 ?
                    (
                        //over 2 days (Day)
                        Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 2 ? `${days[date_start.getDay()]}`
                            //over 1 day (TOMORROW)
                            : Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 1 ? `TOMORROW`
                                //over 1 hour (TODAY)
                                : Math.floor((diff_start) / (1000 * 60 * 60)) >= 1 ? `TODAY`
                                    //over 1 minute (min)
                                    : Math.floor((diff_start) / (1000 * 60)) >= 1 ? `MIN`
                                        //less than 1 minute
                                        : diff_start > 0 ? null
                                            //started (and hasn't been going for longer than 60 mins)
                                            : Math.floor((diff_start) / (1000 * 60)) >= -60 ? null
                                                //ended
                                                : <i className="fas fa-hourglass-end"></i>
                    ) : (
                        //over 2 days (Day)
                        Math.floor((diff_start) / (1000 * 60 * 60 * 24)) >= 1 ? null
                            //date passed (over)
                            : isOver(moment, diff_start) ? <i className="fas fa-hourglass-end"></i>
                                //hasn't started yet (manually set by state)
                                : (moment?.moment_state === 0) ? `SOON`
                                    //started (manually set by state)
                                    : (moment?.moment_state === 1) ? null
                                        //ended (manually set by state)
                                        : <i className="fas fa-hourglass-end"></i>
                    )
    }
    else {
        //hasnt started yet
        return moment?.moment_state === 0 ? null
            //started
            : moment?.moment_state === 1 ? null
                //ended
                : <i className="fas fa-hourglass-end"></i>
    }
}

const parseFullDateTimeString = (moment, date_start) => {
    if (moment?.moment_start != null) {
        return `${daysLong[date_start.getDay()]}, ${date_start.getDate()} ${monthsLong[date_start.getMonth()]} ${date_start.getFullYear()} at ${date_start.getHours().toString().padStart(2, '0')}:${date_start.getMinutes().toString().padStart(2, '0')} (CET)`
    }
    else {
        return 'SOON'
    }
}

export {
    initMoment,
    isOver,
    parseTopTime,
    parseBottomTime,
    parseFullDateTimeString
}