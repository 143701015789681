import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '../css/MomentsPage.css'
import '../css/Loaders.css'
import axios from '../axios';
import { Moment } from '../components/Moment';
import { JoinModal } from '../modals/JoinModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddToCalendar } from '../components/AddToCalendar'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ConsentBanner } from '../components/ConsentBanner'
import { AboutFeed } from '../components/AboutFeed';

export const MomentsPage = () => {
    let { feed_route, moment_id } = useParams();
    moment_id = moment_id == null ? null : moment_id.split('-')[0]
    const [feed, setFeed] = useState(null)
    const [joinedMoments, setJoinedMoments] = useState(localStorage.getItem("soonfeed_joined_moments") != null ? JSON.parse(localStorage.getItem("soonfeed_joined_moments")) : [])
    const [modalData, setModalData] = useState(null)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        axios.get(`moments?feed_route=${feed_route}`).then(response => {
            console.log(response)
            setFeed(
                response.data.result.length > 0 ?
                    {
                        "feed_id": response.data.result[0].feed_id,
                        "feed_title": response.data.result[0].feed_title,
                        "feed_headline": response.data.result[0].feed_headline,
                        "moments": [...response.data.result]
                    }
                    : null
            )
        })
        return () => {

        }
    }, [feed_route])

    useEffect(() => {
        if (feed != null && moment_id != null) {
            setTimeout(() => {
                let highlight_moment = document.getElementById("moment-" + moment_id)
                if (highlight_moment != null) {
                    highlight_moment.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth" })
                    highlight_moment.classList.add("highlight")
                }
            }, 300)
        }
        return () => {

        }
    }, [feed, moment_id])

    useEffect(() => {
        localStorage.setItem("soonfeed_joined_moments", JSON.stringify(joinedMoments))
        return () => {

        }
    }, [joinedMoments])


    const openJoin = (modalData) => {
        console.log(modalData)
        setModalData(modalData)
        setShowModal(true)
    }

    const showToast = (modalData) => {
        console.log(modalData)
        toast.success(
            <div className='toast-join-success'>
                <p>Moment joined!</p>
                <div className='toast-join-buttons'>
                    <button className='toast-join-cancel-button' onClick={() => { cancelJoin(`${modalData.moment.moment_id}`) }}>CANCEL</button>
                    <AddToCalendar moment={modalData.moment} />
                </div>
            </div>,
            {
                position: "bottom-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                toastId: `${modalData.moment.moment_id}`
            });
    }

    const closeModal = (update = false, reset = false) => {
        if (update && !joinedMoments.includes(`${modalData.moment.moment_id}`)) {
            setJoinedMoments(reset ? [`${modalData.moment.moment_id}`] : [...joinedMoments, `${modalData.moment.moment_id}`])
            showToast(modalData)
        }
        setModalData(null)
        setShowModal(false)
    }

    const cancelJoin = (cancel_moment_id) => {
        axios.delete(`moments/cancel?moment_id=${cancel_moment_id}&email=${localStorage.getItem("soonfeed_email")}`).then((response) => {
            console.log(response)
            setJoinedMoments([...joinedMoments.filter((moment_id) => `${moment_id}` !== `${cancel_moment_id}`)])
            toast.dismiss(cancel_moment_id)
        }).catch((error) => {
            if (error?.response?.status === 404) {
                setJoinedMoments([...joinedMoments.filter((moment_id) => `${moment_id}` !== `${cancel_moment_id}`)])
                toast.dismiss(cancel_moment_id)
            }
        })
    }

    return (
        <div className="moments-page">
            <Header />
            <div id='main' className='content-feed-wrapper'>
                <div className='content no-reverse'>
                    <div className='content-left'>

                        {
                            feed == null ?
                                <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="40" cy="40" r="30"></circle>
                                </svg>
                                :
                                <div className='moments-container'>
                                    <div className='moments-header'>
                                        <div className='moments-title'><h2>{feed.feed_title}</h2></div>
                                        <div className='soonfeed-title'>
                                            <h5 className='info'>
                                                <span className='title-join-button'>JOIN</span> the moments you don't want to miss.
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='moments-wrapper'>
                                        {feed.moments.map((moment, moment_index) =>
                                            <Moment moment={moment} openJoin={openJoin} isJoined={joinedMoments.includes(`${moment.moment_id}`)} showToast={showToast} key={moment_index} />
                                        )
                                        }
                                    </div>
                                    <div className='moments-footer'>
                                        <h6 className='guide'>
                                            <a onClick={() => {
                                                document.getElementById('main').scrollTo({ top: document.getElementById('main').scrollHeight, behavior: 'smooth' })
                                            }}>Contact us</a> to embed this soonfeed or to suggest a moment to the editors.
                                        </h6>
                                    </div>
                                </div>
                        }

                        <ToastContainer
                            position="bottom-center"
                            autoClose={6000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover
                            limit={4}
                        />
                    </div>
                    <div className='content-right'>
                        {feed == null ?
                            <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="30"></circle>
                            </svg> :
                            <AboutFeed feed={feed} />
                        }
                    </div>
                </div>
                {
                    feed == null ? null
                        :
                        <Footer />
                }
            </div>
            {
                showModal ?
                    <JoinModal modalData={modalData} closeModal={closeModal} />
                    : null
            }
            <ConsentBanner />
        </div>
    )
}