import '../css/MomentsPage.css'
import '../css/Policy.css'
import 'react-toastify/dist/ReactToastify.css';
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ConsentBanner } from '../components/ConsentBanner'

export const PrivacyPolicy = () => {

    return (
        <div className="cookie-policy-page">
            <Header />
            <div id='main' className='content-feed-wrapper'>
                <div className="policy-content">
                    <h2>Soonfeed Media Privacy Policy</h2>
                    <p>The purpose of this Privacy policy is to inform you, in a transparent way, about data we collect, reasons for collecting it, the way the same data is being processed and the rights you have pertaining to the same.</p>
                    <p>Soonfeed Media is responsible for collection of the data and acts as a data controller with respect to the personal data of users.</p>
                    <div>
                        <h3>1.Why do we collect data?</h3>
                        <p>We use and process your personal data only if necessary to manage and operate our business services and to provide a functional website and content.</p>
                        <p>Collection of your data allows us to enhance and simplify your experience and exploration on our website.</p>
                        <p>
                            We take every precaution related to the usage of your personal data. You can find detailed information below on what we do to protect your privacy.

                        </p>
                    </div>
                    <div>
                        <h3>2.Data we collect from you</h3>
                        <p>We collect your personal and other data for various reasons as outlined under Section 1. It pertains to:</p>
                        <ul><li>Your first name, surname and email address;</li></ul>
                        <ul><li>IP address of the accessing computer; </li></ul>
                        <ul><li>Date and time of access;</li></ul>
                        <ul><li>Browsing identification data, such as: </li>
                            <ul><li>pages you have visited on our website; </li></ul>
                            <ul><li>the type of browser, platform and/or operating system you are using;  </li></ul>
                            <ul><li>the search engine and keywords you are using and  </li></ul>
                            <ul><li>your browsing preferences in general; </li></ul>
                        </ul>
                        <ul><li>Other data you are willing to share with us.</li></ul>
                    </div>
                    <div>
                        <h3>3. How do we collect data?</h3>
                        <p>Most of your data is disclosed to us by your activities while visiting our website. Other data, such as the date and time of your access to our website, the pages you have viewed, or your location data, are collected automatically through servers consulted and "cookies" placed on our website. For more information on what a cookie is, how it is used and the exact data it collects, please see our <a href="/cookie-policy" target="_blank" rel="noreferrer noopener">cookie policy.</a></p>
                        <p>Why do we collect and use data about our users?</p>
                        <ul><li>to improve your experience on our website;</li></ul>
                        <ul><li>to provide the services you signed up for;</li></ul>
                        <ul><li>to create marketing analyses and send you communication when we have your permission or when permitted by law;</li></ul>
                        <ul><li>to enable us to show advertising on our sites.</li></ul>
                        <p>In case you contact us through the contact form, we will process your inquiry and provide you with the information you have requested. This might include responding to your question or sending you an invite to join a Soonfeed Moment, if you have provided us with consent for this.</p>
                    </div>
                    <div>
                        <h3>4. Is your data protected?</h3>
                        <p>We deeply respect the privacy of our users. We manage a strict privacy policy and take all relevant measures to make sure our servers prevent any unauthorised access or use, leak, loss, modification or extinction of your data.</p>
                        <p>Your data is kept only for the time needed to achieve the collection purpose as outlined under Sections 1. and 2. Therefore and once these goals are achieved, data is deleted.</p>
                    </div>
                    <div>
                        <h3>5. Your rights</h3>
                        <p>We believe it is extremely important that you are able to have control under your personal information. According to the relevant regulation, you have the following rights:</p>
                        <ul><li>Right to Access</li></ul>
                        <p>You have the right to obtain confirmation from us, as to whether or not the personal data concerning you is being processed as well as to obtain the access to the respective data. This includes the issuance copy.</p>
                        <ul><li>Right to Rectification</li></ul>
                        <p>You have the right to obtain from us the rectification of inaccurate personal data including the right to complete any incomplete data. We are obligated to provide you with this service without undue delay.</p>
                        <ul><li>Right to Erasure</li></ul>
                        <p>You have the right to obtain the erasure of your personal data, without undue delay, if there is an issue with the underlying legality of the data processing.</p>
                        <ul><li>Right to be forgotten</li></ul>
                        <p>You have the right to obtain from us the erasure of the personal data without undue delay. According to Article 17. of GDPR we will erase your personal data.</p>
                        <ul><li>Right to object</li></ul>
                        <p>You have the right to object to the processing of your personal data at any time by sending an email to the following email address: hello@soonfeed.com.</p>
                        <ul><li>Right against automated decision</li></ul>
                        <p>You have the right not to be subject to a decision based solely on automated data processing, including profiling, which can produce legal effects.</p>
                    </div>
                    <div>
                        <h3>6. How to receive our services?</h3>
                        <p>We want to provide you with the best services related to the information in which you have already expressed interest by joining Soonfeed Moments from our website.</p>
                        <p>Upon receipt of your consent we will place you on our newsletter list and offer you related products or services that you might further be interested in. The same consent pertains to the receipt of the notifications from the Publishers of Soonfeed Moments:</p>
                        <ul><li>The Right Street Digital; Brussels, Belgium.</li></ul>
                        <p>You can unsubscribe and withdraw your consent to these communications at any time by sending an email to the following address: <a href="/cookie-policy" target="_blank" rel="noreferrer noopener">hello@soonfeed.com</a>.</p>
                    </div>
                    <div>
                        <h3>7. Changes to our Privacy policy</h3>
                        <p>This Privacy policy might be subject to change from time to time and in line with the applicable and relevant legislation as well as with industry developments. All of our users will be notified through our website. We recommend that you check this page occasionally for any policy changes.</p>
                        <p>The latest update to this Privacy policy was published on 1 October, 2022</p>
                    </div>
                    <Footer />
                    <ConsentBanner />
                </div>
            </div>
        </div>
    )
}