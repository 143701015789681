import CookieConsent from "react-cookie-consent"
import CLOSE_ICON_BLACK_SVG from '../img/close-icon-black.svg'

export const ConsentBanner = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Approve and Close"
            enableDeclineButton
            declineButtonText={
                <div>
                    <img src={CLOSE_ICON_BLACK_SVG} />
                </div>
            }
            cookieName="SoonfeedConsentCookie"
            expires={365}
        >
            This website uses cookies to enhance the user experience. To learn more you can read our <a href='/cookie-policy' target="_blank" rel='noopener'>Cookie Policy</a>.<br /><br /><a href='/privacy-policy' target="_blank" rel='noopener'>Soonfeed Media's Privacy Policy</a>
        </CookieConsent>
    )
}