import { useState, useEffect } from "react"
import axios from '../../axios';


export const FeedEdit = (params) => {
    let { feed, discardFeed } = params
    const mode = feed.feed_id == null ? "CREATE" : "UPDATE"
    const [id, setID] = useState(feed.feed_id)
    const [title, setTitle] = useState(feed.title)
    const [isPublic, setIsPublic] = useState(feed.public === 1 ? true : false)
    const [feed_route, setFeedRoute] = useState(feed.feed_route)
    const [feed_headline, setFeedHeadline] = useState(feed.feed_headline)

    const saveFeed = () => {
        axios.put(`feeds/${feed.feed_id}`, {
            feed_id: id,
            feed_title: title,
            feed_public: isPublic,
            feed_route: feed_route,
            feed_headline: feed_headline
        }).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    const createFeed = () => {
        axios.post(`feeds`, {
            feed_title: title,
            feed_route: feed_route,
            feed_headline: feed_headline
        }).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    /*
    const deleteFeed = () => {
        axios.delete(`feeds/${feed.feed_id}`).then(response => {
            console.log(response)
            window.location.reload()
        })
    }
    */

    return <div className='feed-edit'>
        {
            mode === "CREATE" ?
                null
                :
                <div className="edit-row">
                    <label htmlFor="feed-id">Feed ID</label>
                    <input name="feed-id" type="number" placeholder="ID" value={id} onChange={(e) => { setID(e.target.value) }} />
                </div>
        }
        <div className="edit-row">
            <label htmlFor="feed-title">Feed Title</label>
            <input name="feed-title" type="text" placeholder="Title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
        </div>
        {
            mode === "CREATE" ?
                null
                :
                <div className="edit-row">
                    <label htmlFor="feed-public">Feed Public</label>
                    <label className="switch">
                        <input name="feed-public" type="checkbox" checked={isPublic} onChange={() => { setIsPublic(!isPublic) }} />
                        <span className="slider round"></span>
                    </label>
                </div>
        }
        <div className="edit-row">
            <label htmlFor="feed-route">Feed Route</label>
            <input name="feed-route" type="text" placeholder="Route" value={feed_route} onChange={(e) => { setFeedRoute(e.target.value) }} />
        </div>
        <div className="edit-row">
            <label htmlFor="feed-headline">Feed Headline</label>
            <input name="feed-headline" type="text" placeholder="Headline" value={feed_headline} onChange={(e) => { setFeedHeadline(e.target.value) }} />
        </div>
        <div className="button-row">{
            mode === "CREATE" ?
                <button className="button-create" onClick={createFeed}>CREATE FEED</button>
                : <button className="button-save" onClick={saveFeed}>SAVE CHANGES</button>
        }
            <button className="button-discard" onClick={discardFeed}>DISCARD CHANGES</button>
            {/*
            <button className="button-delete" onClick={deleteFeed}>DELETE</button>
            */}
        </div>
    </div>
}