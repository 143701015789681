import { NavLink, Route, Routes } from 'react-router-dom'
import './App.css';
import { AdminPanel } from './pages/AdminPanel';
import { AdminLogin } from './pages/AdminLogin';
import { MomentsPage } from './pages/MomentsPage';
import { MainPage } from './pages/MainPage';
import { CookiePolicy } from './pages/CookiePolicy';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='cookie-policy' element={<CookiePolicy />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='admin/login' element={<AdminLogin />} />
        <Route path='admin/*' element={<AdminPanel />} />
        <Route path='/:feed_route' element={<MomentsPage />} />
        <Route path='/:feed_route/moments/:moment_id' element={<MomentsPage />} />
      </Routes>
    </div>
  );
}

export default App;
