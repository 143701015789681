export const User = (params) => {
    let { user } = params

    return <div className='user'>
        <div className="user-header">
            <div className="user-id-name">
                <div className="user-id">
                    User ID: {user.user_id}
                </div>
                <div className="user-name">
                    Name: {user.name}
                </div>
            </div>
            <div className="user-create-update">
                <div className="user-create">
                    Created at: {user.created_at}
                </div>
                <div className="user-update">
                    Updated at: {user.updated_at}
                </div>
            </div>
        </div>
        <div className='user-email'><h2>{user.email}</h2></div>
    </div>
}