import { useState, useEffect } from 'react';
import axios from '../axios';
import { AboutFeedProvider } from './AboutFeedProvider';

export const AboutFeed = (params) => {

    console.log(params)
    const { feed } = params;
    const [feedProviders, setFeedProviders] = useState(null);

    console.log(feed)
    console.log(feed?.feed_id)
    useEffect(
        () => {
            axios.get(`providers/feed/${feed?.feed_id}`).then(response => {
                console.log(response)
                setFeedProviders([...response.data.result])
            })
            return () => {

            }
        }, [])

    return (

        <div className='about-feed'>
            <div className='about-feed-divider'></div>
            <h4 className='about-feed-title'>About this Soonfeed</h4>
            <div className='about-feed-headline'>
                <h5>
                    {feed?.feed_title?.toUpperCase()}
                </h5>
                <h6>{feed?.feed_headline}</h6>
            </div>
            {
                feedProviders == null ?
                    <svg className="loader progress loader-auto" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg> :
                    <div className='display-contents'>
                        <div className='about-feed-providers'>
                            <h5>
                                Editors
                            </h5>
                            {
                                feedProviders?.filter((provider) => provider?.role_name === 'editor')?.map(
                                    (provider, index) =>
                                        <AboutFeedProvider provider={provider} key={index} />
                                )
                            }
                        </div>
                        <div className='about-feed-providers'>
                            <h5>
                                Top Scouts
                            </h5>
                            {
                                feedProviders?.filter((provider) => provider?.role_name === 'scout')?.map(
                                    (provider, index) =>
                                        <AboutFeedProvider provider={provider} key={index} />
                                )
                            }
                        </div>
                        <div className='about-feed-providers'>
                            <h5>
                                Top Sources
                            </h5>
                            {
                                feedProviders?.filter((provider) => provider?.role_name === 'source')?.map(
                                    (provider, index) =>
                                        <AboutFeedProvider provider={provider} key={index} />
                                )
                            }
                        </div>
                    </div>
            }
        </div>
    )
}