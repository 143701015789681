import { useState, useEffect } from "react"
import DateTimePicker from 'react-datetime-picker';
import axios from '../../axios';
import axiosModule from 'axios'


export const MomentEdit = (params) => {
    let { moment, discardMoment } = params
    const mode = moment.moment_id == null ? "CREATE" : "UPDATE"

    const [feeds, setFeeds] = useState(null)
    const [momentTypes, setMomentTypes] = useState(null)
    const [momentTags, setMomentTags] = useState(null)
    const [tags, setTags] = useState(null)

    const [showTags, setShowTags] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])

    const [id, setID] = useState(moment.moment_id)
    const [moment_type_id, setMomentTypeID] = useState(moment.moment_type_id)
    const [feed_id, setFeedID] = useState(moment.feed_id)
    const [moment_order_number, setMomentOrderNumber] = useState(moment.moment_order_number)
    const [isPinned, setIsPinned] = useState(moment.pinned === 1 ? true : false)
    const [isPublic, setIsPublic] = useState(moment.public === 1 ? true : false)
    const [title, setTitle] = useState(moment.title)
    const [moment_start, setMomentStart] = useState(moment.moment_start != null ? new Date(moment.moment_start) : null)
    const [moment_start_CET, setMomentStartCET] = useState(null)
    const [moment_start_UTC, setMomentStartUTC] = useState(null)
    const [moment_state, setMomentState] = useState(moment.moment_state)
    const [start_time_specified, setStartTimeSpecified] = useState(moment.start_time_specified === 1 ? true : false)
    const [live_link, setLiveLink] = useState(moment.live_link)
    const [info_link, setInfoLink] = useState(moment.info_link)
    const [image_link, setImageLink] = useState(moment.image_link)
    const [isSponsored, setIsSponsored] = useState(moment.sponsored === 1 ? true : false)
    const [sponsor_name, setSponsorName] = useState(moment.sponsor_name)
    const [moment_author, setMomentAuthor] = useState(moment.moment_author)
    const [emailed_at, setEmailedAt] = useState(moment.emailed_at != null ? new Date(moment.emailed_at).toISOString() : null)
    const [isEmailed, setIsEmailed] = useState(moment.emailed_at == null ? false : true)

    useEffect(() => {
        const requests = [axios.get(`feeds`), axios.get(`moment_types`), axios.get(`tags/moment_tags/${moment.moment_id}`), axios.get(`tags`)]

        axiosModule.all(requests).then(axiosModule.spread((...responses) => {
            setFeeds(responses[0].data.result)
            setMomentTypes(responses[1].data.result)
            setMomentTags(responses[2].data.result.map((tag) => `${tag.tag_id}`))
            setTags(responses[3].data.result)

            setSelectedTags(responses[2].data.result.map((tag) => `${tag.tag_id}`))

            if (mode === "CREATE") {
                setMomentTypeID(responses[1].data.result[0].moment_type_id)
            }
        })).catch(errors => {
            console.error(errors)
        })

        return () => {

        }
    }, [])

    useEffect(() => {
        setMomentStartUTC(moment_start != null ? new Date(moment_start.getTime()).toISOString() : null)

        var CETTimezoneOffset = 60 * 60000; //offset in milliseconds
        setMomentStartCET(moment_start != null ? new Date(moment_start.getTime() + CETTimezoneOffset).toISOString().slice(0, -1) : null)

        return () => {

        }
    }, [moment_start])

    const saveMoment = () => {
        axios.put(`moments/${moment.moment_id}`, {
            moment_id: id,
            moment_type_id: moment_type_id,
            moment_feed_id: feed_id === "" ? null : feed_id,
            moment_order_number: moment_order_number,
            moment_public: isPublic,
            moment_pinned: isPinned,
            moment_title: title != null && title?.length !== 0 ? title : null,
            moment_start: moment_start_UTC,
            moment_state: moment_state,
            moment_start_time_specified: start_time_specified,
            moment_live_link: live_link != null && live_link?.length !== 0 ? live_link : null,
            moment_info_link: info_link != null && info_link?.length !== 0 ? info_link : null,
            moment_image_link: image_link != null && image_link?.length !== 0 ? image_link : null,
            moment_sponsored: isSponsored,
            moment_sponsor_name: sponsor_name != null && sponsor_name?.length !== 0 ? sponsor_name : null,
            moment_author: moment_author != null && moment_author?.length !== 0 ? moment_author : null,
            moment_emailed_at: isEmailed && emailed_at != null ? emailed_at : null,
            moment_tags: initialTagsUnchanged() ? ["-1"] : selectedTags
            //moment_created_at: created_at,
            //moment_updated_at: updated_at,
        }).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    const createMoment = () => {
        axios.post(`moments`, {
            moment_type_id: moment_type_id,
            moment_feed_id: feed_id === "" ? null : feed_id,
            moment_order_number: moment_order_number,
            moment_pinned: isPinned,
            moment_public: isPublic,
            moment_title: title != null && title?.length !== 0 ? title : null,
            moment_start: moment_start_UTC,
            moment_state: moment_state,
            moment_start_time_specified: start_time_specified,
            moment_live_link: live_link != null && live_link?.length !== 0 ? live_link : null,
            moment_info_link: info_link != null && info_link?.length !== 0 ? info_link : null,
            moment_image_link: image_link != null && image_link?.length !== 0 ? image_link : null,
            moment_sponsored: isSponsored,
            moment_sponsor_name: sponsor_name != null && sponsor_name?.length !== 0 ? sponsor_name : null,
            moment_author: moment_author != null && moment_author?.length !== 0 ? moment_author : null,
            moment_emailed_at: isEmailed && emailed_at != null ? emailed_at : null,
            moment_tags: selectedTags
        }).then(response => {
            console.log(response)
            window.location.reload()
        })
    }

    const toggleTag = (tag_id) => {
        if (selectedTags.includes(tag_id)) {
            setSelectedTags([...selectedTags.filter((tag) => tag !== tag_id)])
        }
        else {
            setSelectedTags([...selectedTags, tag_id])
        }
    }

    const initialTagsUnchanged = () => {
        for (let i = 0; i < momentTags?.length; i++) {
            if (!(selectedTags.includes(momentTags[i]))) {
                return false
            }
        }
        for (let i = 0; i < selectedTags?.length; i++) {
            if (!(momentTags.includes(selectedTags[i]))) {
                return false
            }
        }
        return true
    }

    return feeds == null || momentTypes == null || momentTags == null || tags == null ?
        <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="30"></circle>
        </svg>
        : <div className='moment-edit'>
            {
                mode === "CREATE" ?
                    null
                    :
                    <div className="edit-row">
                        <label htmlFor="moment-id">Moment ID</label>
                        <input name="moment-id" type="number" placeholder="ID" value={id} onChange={(e) => { setID(e.target.value) }} />
                    </div>
            }
            <div className="edit-row">
                <label htmlFor="moment-type">Moment Type</label>
                <select name="moment-type" value={moment_type_id} placeholder="Moment Type" onChange={(e) => { setMomentTypeID(e.target.value) }}>
                    {
                        momentTypes.map((momentType, index) =>
                            <option value={momentType.moment_type_id} key={index}>{momentType.moment_type_name}</option>
                        )
                    }
                </select>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-feed">Moment Feed</label>
                <select name="moment-feed" value={feed_id ?? ""} placeholder="Moment Feed" onChange={(e) => { setFeedID(e.target.value) }}>
                    <option value={""}>Moments by Soonfeed Team</option>
                    {
                        feeds.map((feed, index) =>
                            <option value={feed.feed_id} key={index}>{feed.title}</option>
                        )
                    }
                </select>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-order-number">Moment Order Number</label>
                <input name="moment-order-number" type="number" placeholder="Moment Order Number" value={moment_order_number} onChange={(e) => { setMomentOrderNumber(e.target.value) }} />
            </div>
            <div className="edit-row">
                <label htmlFor="moment-pinned">Moment Pinned</label>
                <label className="switch">
                    <input name="moment-pinned" type="checkbox" checked={isPinned} onChange={() => { setIsPinned(!isPinned) }} />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-public">Moment Public</label>
                <label className="switch">
                    <input name="moment-public" type="checkbox" checked={isPublic} onChange={() => { setIsPublic(!isPublic) }} />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-title">Moment Title</label>
                <textarea name="moment-title" placeholder="Title" rows={2} maxLength="200" value={title} onChange={(e) => { setTitle(e.target.value) }} />
            </div>
            <div className="edit-row-date">
                <div className="moment-datetime-header">Moment Date and Time</div>
                <DateTimePicker showLeadingZeros={true} disableClock={true} onChange={(v) => { setMomentStart(v) }} value={moment_start} />
                <div className="moment-datetime-UTC">
                    YYYY-MM-DD at HH:MM (Timezone)
                </div>
                <div className="moment-datetime-CET">
                    {moment_start_CET?.substring(0, 10)} at {moment_start_CET?.substring(11, 16)} (CET)
                </div>
                <div className="moment-datetime-UTC">
                    {moment_start_UTC?.substring(0, 10)} at {moment_start_UTC?.substring(11, 16)} (UTC)
                </div>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-start-time-specified">Start Time Specified</label>
                <label className="switch">
                    <input name="moment-start-time-specified" type="checkbox" checked={start_time_specified} onChange={() => { setStartTimeSpecified(!start_time_specified) }} />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-state">Moment State</label>
                <select name="moment-state" disabled={start_time_specified} value={moment_state} placeholder="Moment State" onChange={(e) => { setMomentState(e.target.value) }}>
                    <option value={0}>SOON</option>
                    <option value={1}>LIVE</option>
                    <option value={-1}>OVER</option>
                </select>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-live-link">Moment Live Link</label>
                <input name="moment-live-link" type="text" placeholder="Moment Live Link" value={live_link ?? ""} onChange={(e) => { setLiveLink(e.target.value) }} />
            </div>
            <div className="edit-row">
                <label htmlFor="moment-info-link">Moment Info Link</label>
                <input name="moment-info-link" type="text" placeholder="Moment Info Link" value={info_link ?? ""} onChange={(e) => { setInfoLink(e.target.value) }} />
            </div>
            <div className="edit-row-image">
                <div className="edit-row-image-header">
                    <label htmlFor="moment-image-link">Moment Image Link</label>
                    <input name="moment-image-link" type="text" placeholder="Moment Image Link" value={image_link ?? ""} onChange={(e) => { setImageLink(e.target.value) }} />
                </div>
                <div className="moment-image-preview">
                    <label>Image Preview</label>
                    <img src={image_link ?? ""} />
                </div>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-sponsored">Moment Sponsored</label>
                <label className="switch">
                    <input name="moment-sponsored" type="checkbox" checked={isSponsored} onChange={() => { setIsSponsored(!isSponsored) }} />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className="edit-row">
                <label htmlFor="moment-sponsor-name">Sponsor Name</label>
                <input name="moment-sponsor-name" disabled={!isSponsored} type="text" placeholder="Moment Sponsor" value={sponsor_name ?? ""} onChange={(e) => { setSponsorName(e.target.value) }} />
            </div>
            <div className="edit-row">
                <label htmlFor="moment-author">Moment Author</label>
                <input name="moment-author" type="text" placeholder="Moment Author" value={moment_author ?? ""} onChange={(e) => { setMomentAuthor(e.target.value) }} />
            </div>
            <div className="edit-row">
                <label htmlFor="moment-emailed-at">Moment Emailed</label>
                <label className="switch">
                    <input name="moment-emailed" type="checkbox" checked={isEmailed} onChange={() => { setIsEmailed(!isEmailed) }} />
                    <span className="slider round"></span>
                </label>
                <div className="moment-datetime-UTC">
                    {emailed_at?.substring(0, 10)} at {emailed_at?.substring(11, 16)} (UTC)
                </div>
            </div>
            <div className={"edit-row-tags" + (showTags ? " show-tags" : "")}>
                <div className="edit-row-tags-header" onClick={() => { setShowTags(!showTags) }}>
                    <label>Moment Tags</label>
                    {
                        showTags ?
                            <i className="fas fa-chevron-up"></i>
                            : <i className="fas fa-chevron-down"></i>
                    }
                </div>
                <div className="moment-selected-tags">
                    {
                        tags.map((tag, index) =>
                            <div className="moment-selected-tag" key={index}>
                                <input name={tag.tag_name} type="checkbox" checked={selectedTags.includes(`${tag.tag_id}`)} onChange={(e) => { toggleTag(`${tag.tag_id}`) }} />
                                <label htmlFor={tag.tag_name}>{tag.tag_name}</label>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="button-row">{
                mode === "CREATE" ?
                    <button className="button-create" onClick={createMoment}>CREATE MOMENT</button>
                    : <button className="button-save" onClick={saveMoment}>SAVE CHANGES</button>
            }
                <button className="button-discard" onClick={discardMoment}>DISCARD CHANGES</button>
            </div>
        </div>
}