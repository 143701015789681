export const Admin = (params) => {
    let { admin } = params

    return <div className='admin'>
        <div className="admin-header">
            <div className="admin-id-name">
                <div className="admin-id">
                    Admin ID: {admin.admin_id}
                </div>
                <div className="admin-name">
                    Full Name: {admin.name}
                </div>
            </div>
            <div className="admin-create-update">
                <div className="admin-create">
                    Created at: {admin.created_at}
                </div>
                <div className="admin-update">
                    Updated at: {admin.updated_at}
                </div>
            </div>
        </div>
        <div className='admin-email'><h2>{admin.email}</h2></div>
    </div>
}