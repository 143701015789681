import { useState, useEffect } from 'react'
import { useNavigate, NavLink, Route, Routes } from "react-router-dom";
import SOONFEED_LOGO from '../img/sf-logo.svg'
import '../css/AdminPanel.css'
import axios from '../axios';
import { AdminLogin } from './AdminLogin';
import { AdminMoments } from './admin_tabs/AdminMoments';
import { AdminFeeds } from './admin_tabs/AdminFeeds';
import { AdminUsers } from './admin_tabs/AdminUsers';
import { AdminAdmins } from './admin_tabs/AdminAdmins';

export const AdminPanel = (params) => {

    const [tab, setTab] = useState('moments');

    let navigate = useNavigate();

    useEffect(() => {
        axios.get(`admin/`).then(response => {
            console.log(response)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                navigate("/admin/login");
            }
            else {
                console.error(error)
            }
        })
        return () => {

        }
    }, [])

    const logout = () => {
        axios.delete(`admin/logout`).then(response => {
            console.log(response)
            navigate("/admin/login");
        }).catch((error) => {
            if (error?.response?.status === 403) {
                navigate("/admin/login");
            }
            else {
                console.error(error)
            }
        })
    }

    return <div className="admin-panel">
        <header className='admin-header'>
            <div id="soonfeed-link">
                <a href='/'>
                    <img src={SOONFEED_LOGO} alt="Soonfeed - Moments Beforetime" title='Soonfeed logo' height="50" />
                </a>
            </div>
            <NavLink className='admin-tab' to='/admin/moments' style={({ isActive }) => { return { backgroundColor: isActive ? 'var(--moment-background-color-active)' : 'transparent' } }}>
                Moments
            </NavLink>
            <NavLink className='admin-tab' to='/admin/feeds' style={({ isActive }) => { return { backgroundColor: isActive ? 'var(--moment-background-color-active)' : 'transparent' } }}>
                Feeds
            </NavLink>
            <NavLink className='admin-tab' to='/admin/users' style={({ isActive }) => { return { backgroundColor: isActive ? 'var(--moment-background-color-active)' : 'transparent' } }}>
                Users
            </NavLink>
            <NavLink className='admin-tab' to='/admin/admins' style={({ isActive }) => { return { backgroundColor: isActive ? 'var(--moment-background-color-active)' : 'transparent' } }}>
                Admins
            </NavLink>
            <div className='admin-tab' onClick={logout}>
                Logout <i className="fas fa-sign-out-alt"></i>
            </div>
        </header >
        <div className='admin-content'>
            <Routes>
                <Route path='moments/' element={<AdminMoments />} />
                <Route path='feeds/' element={<AdminFeeds />} />
                <Route path='users/' element={<AdminUsers />} />
                <Route path='admins/' element={<AdminAdmins />} />
            </Routes>
        </div>
    </div >
}