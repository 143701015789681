import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from '../../axios';
import { Feed } from '../../components/admin_components/Feed';
import { FeedEdit } from '../../components/admin_components/FeedEdit';

export const AdminFeeds = (params) => {

    const [loading, setLoading] = useState(false)
    const [selectedFeed, setSelectedFeed] = useState(null)

    const [feeds, setFeeds] = useState([])

    useEffect(() => {
        axios.get(`feeds?all=true`).then(response => {
            console.log(response)
            setFeeds([...response.data.result])
        })
        return () => {

        }
    }, [])

    const discardFeed = () => {
        setSelectedFeed(null)
    }

    return <div className="admin-feeds">
        <div className='content-left'>
            {
                feeds == null ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    :
                    feeds.map((feed, feed_index) =>
                        <div key={feed_index} className={'feed-select' + (selectedFeed?.feed_id === feed.feed_id ? ' selected' : '')} onClick={selectedFeed == null ? () => { setSelectedFeed({ ...feed }) } : null}>
                            <Feed feed={feed} />
                        </div>
                    )
            }
        </div>
        <div className='content-right'>
            <div className='admin-editor'>
                {
                    selectedFeed != null ?
                        <FeedEdit feed={selectedFeed} discardFeed={discardFeed} />
                        : <div className='empty-editor'>
                            <p>Select a feed to start editing, or</p>
                            <button className='button-create' onClick={() => { setSelectedFeed({ feed_id: null, title: "", public: 0 }) }}><i className="fas fa-plus"></i> CREATE FEED</button>
                        </div>
                }
            </div>
        </div>
    </div>
}