import { useState, useEffect } from "react"
import * as time_utils from '../../utils/time_utils'


export const Moment = (params) => {
    let { moment } = params
    // eslint-disable-next-line
    const [date_now, setDateNow] = useState(Date.now())

    useEffect(() => {
        let date_start = moment?.moment_start != null ? new Date(moment.moment_start) : null
        let diff_start = date_start != null ? date_start - new Date(Date.now() + (new Date()).getTimezoneOffset() * 60 * 1000) : null
        if (date_start != null && diff_start != null && moment.start_time_specified === 1 && Math.abs(diff_start) < 4 * 60 * 60 * 1000) {
            const interval = setInterval(() => setDateNow(Date.now()), 1000)
            return () => {
                clearInterval(interval)
            }
        }
        else {
            return () => {

            }
        }
    }, [moment?.moment_start, moment?.start_time_specified])


    const { date_start, diff_start } = time_utils.initMoment(moment)
    const isOver = time_utils.isOver(moment, diff_start)
    const topTime = time_utils.parseTopTime(moment, date_start, diff_start)
    const bottomTime = time_utils.parseBottomTime(moment, date_start, diff_start)
    const fullTime = time_utils.parseFullDateTimeString(moment, date_start)

    return <div id={"moment-" + moment.moment_id}
        className={"moment"
            + (moment.pinned === 1 ? " pinned" : "")
            + (isOver ? " over" : "")}>
        <div className="moment-left" title={fullTime}>
            <div className="moment-start-top">

                <svg
                    width="100%"
                    height="100%"
                    viewBox={"0 0 " + (topTime.split('\n')[0].length <= 2 ? 140 : topTime.split('\n')[0].length * 60) + " 75"}
                    preserveAspectRatio="xMinYMid meet"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <text
                        x="50%"
                        y={topTime.split('\n').length > 1 ? "50%" : "75"}
                        fontSize="75"
                    >
                        <tspan>
                            {topTime.split('\n')[0]}
                        </tspan>
                        {topTime.split('\n').length > 1 ?
                            <tspan x="50%" dy="90">
                                {topTime.split('\n')[1]}
                            </tspan>
                            : null
                        }
                    </text>
                </svg>
            </div>
            <div className="moment-start-bottom">
                {typeof bottomTime === 'string' ?
                    <svg
                        width="100%"
                        height="100%"
                        viewBox={"0 0 " + (bottomTime.length <= 2 ? 140 : Math.min(bottomTime.length, 6) * 80) + " 95"}
                        preserveAspectRatio="xMinYMid meet"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <text
                            x="50%"
                            y="75"
                            fontSize="75"
                        >
                            <tspan>
                                {bottomTime}
                            </tspan>
                        </text>
                    </svg>
                    : bottomTime
                }
            </div>
        </div>

        <div className="moment-center">
            <div className="moment-center-top">
                <div className="tags-container">
                    {
                        moment.tags == null
                            ? null
                            : moment.tags.split(',').map((tag, index) => <span className={"tag " + tag.toLowerCase().replace(/\s/g, "-")} key={index}>{tag}</span>)
                    }
                </div>
                {moment.pinned === 1 ?
                    <div className="moment-pinned"><i className="fas fa-thumbtack"></i></div>
                    : null
                }
            </div>
            <div className="moment-center-middle">
                <h3>
                    {moment.title}
                </h3>
            </div>
            <div className="moment-center-bottom">
                <div className="moment-author">
                    <div className="moment-author-avatar">
                        <img src="/logo.jpg" alt="Soonfeed logo"></img>
                    </div>
                    <div className="moment-author-name">
                        {
                            moment.moment_author ?? "Soonfeed team"
                        }
                    </div>
                </div>
                <div className="moment-info-share">
                    {
                        moment.live_link != null ?
                            <a href={moment.live_link} target="_blank" rel="noreferrer" title="Open Link">
                                <span className="open-link">
                                    <i className="fas fa-external-link-alt"></i>
                                </span>
                            </a>
                            : null
                    }
                    {
                        moment.info_link != null ?
                            <a href={moment.info_link} target="_blank" rel="noreferrer" title="Latest info">
                                <span className="more-info">
                                    <i className="fas fa-info"></i>
                                </span>
                            </a>
                            : null
                    }
                    <span className="share" title="Share on Social Media">
                        <i className="fas fa-share-alt"></i>
                    </span>
                </div>
            </div>
        </div>
        <div className="moment-vertical-divider"></div>
        <div className="moment-right">
            {
                moment.sponsored === 1 ?
                    <div className="moment-sponsored">
                        *sponsored
                    </div>
                    : null
            }
            <div className="moment-join">
                <p>JOIN</p>
            </div>
        </div>
    </div>
}