import { useEffect } from 'react'
import 'add-to-calendar-button/assets/css/atcb.css';
import { atcb_init } from 'add-to-calendar-button';

export const AddToCalendar = (params) => {
    const { moment } = params

    useEffect(() => {
        atcb_init()
        return () => {

        }
    }, []);

    const event = {
        "event": {
            "@context": "https://schema.org",
            "@type": "Event",
            "name": moment.title,
            "description": moment.subtitle,
            "startDate": moment.moment_start != null ?
                (
                    moment.start_time_specified === 1 ?
                        moment.moment_start
                        : new Date(moment.moment_start).toISOString().substring(0, 10)
                ) : null,
            "endDate": moment.moment_start != null && moment.start_time_specified === 1 ?
                new Date(new Date(moment.moment_start).getTime() + 60 * 60000).toISOString()
                : null,
            "location": moment.location
        },
        "label": "ADD TO CALENDAR",
        "options": [
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "MicrosoftTeams",
            "Outlook.com",
            "Yahoo"
        ],
        //"timeZone": "Europe/Berlin",
        "timeZoneOffset": "+00:00",
        "trigger": "click",
        "iCalFileName": `Reminder-${moment.title}`,
        "listStyle": "modal"
    }

    return (
        <div className='atcb'>
            <script type="application/ld+json">
                {JSON.stringify(event)}
            </script>
        </div>
    );
}