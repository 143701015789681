import { useState, useEffect } from "react"


export const Feed = (params) => {
    let { feed } = params

    return <div className='feed'>
        <div className="feed-header">
            <div className="feed-id">
                Feed ID: {feed.feed_id}
            </div>
            <div className="feed-route">
                /{feed.feed_route}
            </div>
            <div className="feed-public">
                {feed.public === 1 ? "PUBLIC" : ""}
            </div>
        </div>
        <div className='feed-headline'><h3>{feed.feed_headline}</h3></div>
        <div className='feed-title'><h2>{feed.title}</h2></div>
    </div>
}