import { useState, useEffect } from 'react'
import '../css/MomentsPage.css'
import '../css/Loaders.css'
import axios from '../axios';
import { FeaturedMoment } from '../components/FeaturedMoment';
import { JoinModal } from '../modals/JoinModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddToCalendar } from '../components/AddToCalendar'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ConsentBanner } from '../components/ConsentBanner'

export const MainPage = () => {

    const featured_css_vars = [
        '--content-right-min-width',
        '--featured-main-margin',
        '--featured-main-min-width',
        '--featured-secondary-total-width',
        '--featured-secondary-margin'
    ]

    const featured_css_var_map = {
        0: {
            '--featured-main-margin': '0',
            '--featured-main-min-width': '0',
            '--featured-secondary-total-width': '0',
            '--featured-secondary-margin': '0',
            '--content-right-min-width': '0'
        },
        1: {
            '--featured-main-margin': '0',
            '--featured-main-min-width': '66%',
            '--featured-secondary-total-width': '0',
            '--featured-secondary-margin': '0',
            '--content-right-min-width': '50%'
        },
        2: {
            '--featured-main-margin': '14px',
            '--featured-main-min-width': 'calc(50% - var(--featured-main-margin))',
            '--featured-secondary-total-width': '50%',
            '--featured-secondary-margin': '0',
            '--content-right-min-width': '60%'
        },
        3: {
            '--featured-main-margin': '14px',
            '--featured-main-min-width': 'calc(50% - var(--featured-main-margin))',
            '--featured-secondary-total-width': 'calc(50% - 12px)',
            '--featured-secondary-margin': '16px',
            '--content-right-min-width': '60%'
        },
        4: {
            '--featured-main-margin': '14px',
            '--featured-main-min-width': 'calc(66% - var(--featured-main-margin))',
            '--featured-secondary-total-width': 'calc(33% - 12px)',
            '--featured-secondary-margin': '16px',
            '--content-right-min-width': '55%'
        },
        5: {
            '--featured-main-margin': '10px',
            '--featured-main-min-width': 'calc(71% - var(--featured-main-margin))',
            '--featured-secondary-total-width': '24.6%',
            '--featured-secondary-margin': '2%',
            '--content-right-min-width': '55%'
        }
    }

    const [featuredMoments, setFeaturedMoments] = useState(null)
    const [toggleFeaturedKey, setToggleFeaturedKey] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [joinedMoments, setJoinedMoments] = useState(localStorage.getItem("soonfeed_joined_moments") != null ? JSON.parse(localStorage.getItem("soonfeed_joined_moments")) : [])

    useEffect(() => {
        axios.get(`moments/featured`).then(response => {
            console.log(response)
            const index = response.data.result?.length != null && response.data.result?.length < 6 ? response.data.result?.length : 0
            for (let var_name of featured_css_vars) {
                console.log(var_name, featured_css_var_map[index][var_name])
                document.documentElement.style.setProperty(var_name, featured_css_var_map[index][var_name])
            }
            setFeaturedMoments([...response.data.result])
        }).catch((error) => {
            if (error?.response?.status === 404) {
                setFeaturedMoments([])
            }
        })
        return () => {

        }
    }, [])

    useEffect(() => {
        localStorage.setItem("soonfeed_joined_moments", JSON.stringify(joinedMoments))
        return () => {

        }
    }, [joinedMoments])


    const openJoin = (modalData) => {
        console.log(modalData)
        setModalData(modalData)
        setShowModal(true)
    }

    const showToast = (modalData) => {
        console.log(modalData)
        toast.success(
            <div className='toast-join-success'>
                <p>Moment joined!</p>
                <div className='toast-join-buttons'>
                    <button className='toast-join-cancel-button' onClick={() => { cancelJoin(`${modalData.moment.moment_id}`) }}>CANCEL</button>
                    {/*
                    <button className='toast-join-calendar-button'>ADD TO CALENDAR</button>
                    */}
                    <AddToCalendar moment={modalData.moment} />
                </div>
            </div>,
            {
                position: "bottom-center",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                toastId: `${modalData.moment.moment_id}`
            });
    }

    const closeModal = (update = false, reset = false) => {
        if (update && !joinedMoments.includes(`${modalData.moment.moment_id}`)) {
            setJoinedMoments(reset ? [`${modalData.moment.moment_id}`] : [...joinedMoments, `${modalData.moment.moment_id}`])
            showToast(modalData)
        }
        setModalData(null)
        setShowModal(false)
    }

    const cancelJoin = (cancel_moment_id) => {
        axios.delete(`moments/cancel?moment_id=${cancel_moment_id}&email=${localStorage.getItem("soonfeed_email")}`).then((response) => {
            console.log(response)
            setJoinedMoments([...joinedMoments.filter((moment_id) => `${moment_id}` !== `${cancel_moment_id}`)])
            toast.dismiss(cancel_moment_id)
        })
    }

    const offset = (arr, offset) => [...arr.slice(offset), ...arr.slice(0, offset)];

    const offsetNext = (index) => {
        document.getElementById('main').scrollTo({ top: 0, behavior: 'smooth' })
        setFeaturedMoments([...offset(featuredMoments, index)])
    }

    return (
        <div className="main-page">
            <Header />
            <div id='main' className='content-feed-wrapper'>
                <div className='content'>
                    <div className='content-left'>
                        <div className='soonfeed-info-container'>
                            <div className='soonfeed-info'>
                                <h2 className='soonfeed-info-title'>What's Soonfeed?</h2>
                                <h4 className='info'>
                                    Soonfeed is a media platform that displays online moments before they happen, so you can discover and join the moments you care about.
                                </h4>
                                <h5 className='guide'>
                                    <span className='title-join-button'>JOIN</span> to get an email or calendar alert that takes you there as it happens.
                                </h5>
                                <h6 className='guide'>
                                    <a onClick={() => {
                                        document.getElementById('main').scrollTo({ top: document.getElementById('main').scrollHeight, behavior: 'smooth' })
                                    }}>Contact our editors</a> to request a soonfeed moment, or to embed a soonfeed widget on your website.
                                </h6>
                            </div>
                        </div>
                    </div>
                    {
                        featuredMoments == null ?
                            <svg className="loader progress loader-auto" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="30"></circle>
                            </svg>
                            :
                            featuredMoments?.length === 0 ?
                                null :
                                <div className='content-right' key={toggleFeaturedKey}>
                                    <div className={'featured-moment-wrapper' + (featuredMoments?.length === 1 ? ' featured-single' : '')}>
                                        <h3>Featured Moment{featuredMoments?.length > 1 ? 's' : ''}</h3>

                                        <div className='featured-moments-container'>
                                            <FeaturedMoment moment={featuredMoments[0]} secondary={0} openJoin={openJoin} isJoined={joinedMoments.includes(`${featuredMoments[0].moment_id}`)} showToast={showToast} />
                                            <div className='secondary-featured-moments'>
                                                {featuredMoments.map((featuredMoment, featuredMoment_index) =>
                                                    featuredMoment_index === 0 ?
                                                        null
                                                        :
                                                        <FeaturedMoment moment={featuredMoment} secondary={1} offset={() => { offsetNext(featuredMoment_index); setToggleFeaturedKey(!toggleFeaturedKey) }} openJoin={openJoin} isJoined={joinedMoments.includes(`${featuredMoment?.moment_id}`)} showToast={showToast} key={featuredMoment_index} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                </div>

                <ToastContainer
                    position="bottom-center"
                    autoClose={6000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    limit={4}
                />
                <Footer />
            </div>
            {
                showModal ?
                    <JoinModal modalData={modalData} closeModal={closeModal} />
                    : null
            }
            <ConsentBanner />
        </div>
    )
}