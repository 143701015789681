import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axios from '../../axios';
import { Moment } from '../../components/admin_components/Moment';
import { MomentEdit } from '../../components/admin_components/MomentEdit';
import '../../css/AdminPanel.css'

export const AdminMoments = (params) => {

    const [loading, setLoading] = useState(false)
    const [selectedMoment, setSelectedMoment] = useState(null)

    const [feeds, setFeeds] = useState([])
    //const [feedsFiltered, setFeedsFiltered] = useState([])
    /*
    const [filters, setFilters] = useState([
        {
            "type": "LIVE MEDIA",
            "active": false
        },
        {
            "type": "PUBLICATION",
            "active": false
        },
        {
            "type": "SPECULATION",
            "active": false
        },
    ])
    */

    const discardMoment = () => {
        setSelectedMoment(null)
    }

    useEffect(() => {
        axios.get(`moments?all=true`).then(response => {
            console.log(response)
            let feedsNew = [{
                "feed_id": -1,
                "feed_title": "Moments by Soonfeed Team",
                "moments": []
            }]
            for (let i = 0; i < response.data.result?.length; i++) {
                let moment = response.data.result[i];
                if (moment.feed_id == null) {
                    feedsNew[0].moments.push(moment)
                }
                else {
                    let feed_index = feedsNew.findIndex((feed) => feed.feed_id === moment.feed_id)
                    if (feed_index === -1) {
                        feedsNew.push({
                            "feed_id": moment.feed_id,
                            "feed_title": moment.feed_title,
                            "moments": [moment]
                        })
                    }
                    else {
                        feedsNew[feed_index].moments.push(moment)
                    }
                }
            }
            feedsNew.filter((feed) => feed.moments.length > 0)
            feedsNew.sort((a, b) => b.feed_id - a.feed_id)
            /*
            let feedsNewFiltered = []
            for (let i = 0; i < feedsNew.length; i++) {
                feedsNewFiltered.push({
                    "feed_id": feedsNew[i].feed_id,
                    "feed_title": feedsNew[i].feed_title,
                    "moments": [...feedsNew[i].moments]
                })
            }
            setFeedsFiltered([...feedsNewFiltered])
            */
            setFeeds([...feedsNew])
        })
        return () => {

        }
    }, [])

    return <div className="admin-moments">
        <div className='content-left'>
            {/*
                <div className='filters-container'>
                    <div className='filters-title'><p>Filters</p></div>
                    <div className='filters-row'>
                        {
                            filters.map((filter, index) =>
                                <span className='filter-box' onClick={(e) => {
                                    let filtersNew = filters;
                                    filtersNew[index].active = !filtersNew[index].active
                                    setFilters([...filtersNew])
                                }}>
                                    <input name={filter.type} type="checkbox" checked={filter.active} />
                                    <label htmlFor={filter.type}>{filter.type}</label>
                                </span>
                            )
                        }
                    </div>
                </div>
                */}

            {
                /*feedsFiltered == null ?*/
                feeds == null ?
                    <svg className="loader progress loader-relative" width="80" height="80" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="30"></circle>
                    </svg>
                    :
                    <div className='feeds-container'>
                        {feeds.map((feed, feed_index) =>
                            <div className='moments-container' key={feed_index}>
                                <div className='moments-title'><h2>{feed.feed_title}</h2></div>
                                {feed.moments.map((moment, moment_index) =>
                                    <div className={'moment-select' + (selectedMoment?.moment_id === moment.moment_id ? ' selected' : '')} key={moment_index} onClick={selectedMoment == null ? () => { setSelectedMoment({ ...moment }) } : null}>
                                        <Moment moment={moment} />
                                    </div>
                                )
                                }
                            </div>
                        )

                        }
                    </div>
            }
        </div>
        <div className='content-right'>
            <div className='admin-editor'>
                {
                    selectedMoment != null ?
                        <MomentEdit moment={selectedMoment} discardMoment={discardMoment} />
                        : <div className='empty-editor'>
                            <p>Select a moment to start editing, or</p>
                            <button className='button-create' onClick={() => {
                                setSelectedMoment({

                                    moment_id: null,
                                    moment_type_id: null,
                                    feed_id: null,
                                    moment_order_number: 99,
                                    pinned: 0,
                                    public: 0,
                                    title: "",
                                    subtitle: "",
                                    moment_start: null,
                                    moment_state: -1,
                                    start_time_specified: false,
                                    live_link: "",
                                    sponsor_name: "",
                                    emailed_at: null,
                                    created_at: null,
                                    updated_at: null,
                                })
                            }}><i className="fas fa-plus"></i> CREATE MOMENT</button>
                        </div>
                }
            </div>
        </div>
    </div>
}